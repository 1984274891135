import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BalanceIcon from '@mui/icons-material/Balance';
import { getAvatarContent, fetchProfile } from '../utils/userUtils';
import ShareItysModal from './ShareItysModal';
import './ItysItem.css';

const ItysItem = ({ itys, forceShowDetails = false, editable = true, currentProfile = null }) => {
  const [showSignatories, setShowSignatories] = useState(false);
  const [showChallengers, setShowChallengers] = useState(false);
  const [showSpectators, setShowSpectators] = useState(false);
  const [creator, setCreator] = useState(null);
  const [signatories, setSignatories] = useState([]);
  const [challengers, setChallengers] = useState([]);
  const [spectators, setSpectators] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  useEffect(() => {
    fetchPersonDetails();
  }, [itys]);

  const showShareButton = (currentProfile != null);
  if (!(currentProfile && itys.Creator && itys.Creator == currentProfile._id))
    editable = false;

  const fetchPersonDetails = async () => {
    try {
      const creatorDetails = await fetchProfile(itys.Creator);
      setCreator(creatorDetails);

      const signatoriesDetails = await Promise.all(itys.Signatory.map(id => fetchProfile(id)));
      setSignatories(signatoriesDetails);

      const challengersDetails = await Promise.all(itys.Challenger.map(id => fetchProfile(id)));
      setChallengers(challengersDetails);

      const spectatorsDetails = await Promise.all(itys.Spectator.map(id => fetchProfile(id)));
      setSpectators(spectatorsDetails);
    } catch (error) {
      console.error('Error fetching person details:', error);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'I was Right':
        return { className: 'status-right', emoji: '😊' };
      case 'I was Wrong':
        return { className: 'status-wrong', emoji: '😔' };
      default:
        return { className: 'status-pending', emoji: '🤔' };
    }
  };

  const getCardBackgroundColor = (status) => {
    switch (status) {
      case 'I was Right':
        return '#e8f5e9';
      case 'I was Wrong':
        return '#ffebee';
      default:
        return '#ffffff';
    }
  };

  const renderAvatars = (personList) => {
    return personList.map((person, index) => (
      <div key={index} className="avatar-item">
        <Avatar className="avatar" {...getAvatarContent(person.photoPreview, person.userData.PersonFirstName, person.userData.PersonLastName)} />
        <span className="avatar-name">{person.displayName}</span>
      </div>
    ));
  };

  const statusStyle = getStatusStyle(itys.FinalSettlementStatus);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(',', '');
  };

  const handleShareClick = () => {
    setShareModalOpen(true);
  };

  return (
    <div className="itys-card" style={{ backgroundColor: getCardBackgroundColor(itys.FinalSettlementStatus) }}>
      <div className="itys-header-top">
        <span className="itys-visibility">{itys.Visibility}</span>
        <span className={`itys-status ${statusStyle.className}`}>
          {statusStyle.emoji} {itys.FinalSettlementStatus}
        </span>
        {editable && (
          <IconButton className="edit-button" component={Link} to={`/edit/${itys.ItysId}`} state={{ editMode: 'full' }} >
            <EditIcon />
          </IconButton> 
        )}
      </div>
      {creator && (
        <div className="itys-header-bottom">
          <div className="itys-creator">
            <Avatar className="creator-avatar" {...getAvatarContent(creator.photoPreview, creator.userData.PersonFirstName, creator.userData.PersonLastName)} />
            <span className="creator-name">{creator.displayName}</span>
          </div>
          <div className="itys-date">
            <BalanceIcon /> <span>{formatDate(itys.TimeOfReckoning)}</span>
          </div>
        </div>
      )}
      <div className="itys-main-content">
        <h2 className="itys-title">{itys.Title}</h2>
        <p className="itys-content">{itys.Content}</p>
      </div>
      <div className="itys-footer-top">
        <button className="group-button" onClick={() => setShowSignatories(!showSignatories)}>
          <GroupIcon /> <span>Signatories</span>
        </button>
        <button className="group-button" onClick={() => setShowSpectators(!showSpectators)}>
          <GroupIcon /> <span>Spectators</span>
        </button>
        <button className="group-button" onClick={() => setShowChallengers(!showChallengers)}>
          <GroupIcon /> <span>Challengers</span>
        </button>
      </div>
      {(showSignatories || forceShowDetails) && signatories.length > 0 && (
        <div className="itys-group-details">
          <div className="group-header">
            <h3>Signatories</h3>
            {(editable && (
              <IconButton
                className="add-member-button"
                component={Link}
                to={`/edit/${itys.ItysId}`}
                state={{ editMode: 'participants' }}
              >
                <PersonAddIcon />
              </IconButton>
            ))}
          </div>
          <div className="avatar-list">{renderAvatars(signatories)}</div>
        </div>
      )}
      {(showChallengers || (forceShowDetails && challengers.length > 0)) && (
        <div className="itys-group-details">
          <div className="group-header">
            <h3>Challengers</h3>
            {(editable && (
              <IconButton
                className="add-member-button"
                component={Link}
                to={`/edit/${itys.ItysId}`}
                state={{ editMode: 'participants' }}
              >
                <PersonAddIcon />
              </IconButton>
            ))}
          </div>
          <div className="avatar-list">{renderAvatars(challengers)}</div>
        </div>
      )}
      {(showSpectators || (forceShowDetails && spectators.length > 0)) && (
        <div className="itys-group-details">
          <div className="group-header">
            <h3>Spectators</h3>
            {(editable && (
              <IconButton
                className="add-member-button"
                component={Link}
                to={`/edit/${itys.ItysId}`}
                state={{ editMode: 'participants' }}
              >
                <PersonAddIcon />
              </IconButton>
            ))}
          </div>
          <div className="avatar-list">{renderAvatars(spectators)}</div>
        </div>
      )}
      {(showShareButton && (
        <div className="itys-footer-bottom">
          <div className="itys-actions">
            <button className="action-button">
              <ChatOutlinedIcon /> <span>Comment</span>
            </button>
            <button className="action-button" onClick={handleShareClick}>
              <ShareOutlinedIcon /> <span>Share</span>
            </button>
          </div>
        </div>
      ))}
      <ShareItysModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        itysId={itys.ItysId}
        itysContent={itys}
      />
    </div>
  );
};

export default ItysItem;