import axios from 'axios';

export const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = 'https://api.itoldyouso.in';
      break;
    case 'development':
      url = process.env.REACT_APP_API_URL;
      break;
    default:
      url = 'http://192.168.0.102:10020';
  }

  console.log('getBaseUrl URL is : ', url);
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_API_URL);
  return url;
}

const api = axios.create({
  //baseURL: process.env.REACT_APP_API_URL || 'http://192.168.0.102:10020',
  //baseURL: process.env.REACT_APP_API_URL || 'http://13.232.210.191:10020',
  baseURL: getBaseUrl(),
});

// List of endpoints that should bypass the interceptor's error handling
const bypassEndpoints = ['/auth/local/login', '/auth/local/signup', '/auth/google/callback'];

// Create a function to set up the interceptor
export const setupInterceptors = (navigate) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      // Check if the request URL is in the bypass list
      const requestUrl = error.config.url;
      if (bypassEndpoints.some(endpoint => requestUrl.includes(endpoint))) {
        return Promise.reject(error);  // Pass through the original error
      }

      // Generic error message
      let errorMessage = 'An error occurred. Please try again later.';

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 400 || error.response.status === 401) {
          errorMessage = 'Your session has expired. Please log in again.';
          // Clear any stored authentication tokens
          localStorage.removeItem('token');
          // Redirect to the Auth page
          navigate('/auth');
        } else if (error.response.status === 403) {
          errorMessage = 'You do not have permission to perform this action.';
        } else if (error.response.status === 404) {
          errorMessage = 'The requested resource was not found.';
        } else if (error.response.status >= 500) {
          errorMessage = 'A server error occurred. Please try again later.';
        }
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = 'No response received from the server. Please check your internet connection.';
      }

      // Create a new error with the generic message
      const genericError = new Error(errorMessage);
      genericError.isAxiosError = true;
      return Promise.reject(genericError);
    }
  );
};

export default api;
