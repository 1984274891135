import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import html2canvas from 'html2canvas';
import axios from '../services/api';
import ItysItem from './ItysItem';

const ShareItysModal = ({ open, onClose, itysId, itysContent }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [generateSnapshot, setGenerateSnapshot] = useState(false);
  const [snapshotUrl, setSnapshotUrl] = useState(null);
  const [ssBlob, setSsBlob] = useState(null);
  //const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');

  /* useEffect(() => {
    const checkMobile = () => {
      const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent); 
      setIsMobile(mobile);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []); */

  const shareableLink = `${window.location.origin}/itys/${itysId}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareableLink);
    setSnackbarMessage('Link copied to clipboard!');
    setSnackbarOpen(true);
  };

  const generateAndSaveSnapshot = useCallback(async () => {
    setIsLoading(true);
    try {
      const snapshotContainer = document.getElementById('snapshot-container');
      if (snapshotContainer) {
        const canvas = await html2canvas(snapshotContainer);
        const snapshotBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
        
        // Save snapshot in the component for sharing later on
        setSsBlob(snapshotBlob);

        // Save snapshot in S3 for accessing via hyperlink outside of the application.
        const formData = new FormData();
        formData.append('snapshot', snapshotBlob, 'snapshot.png');

        const response = await axios.post(`/itys/${itysId}/snapshot`, formData, {
          headers: { 
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true
        });

        console.log('API Response:', response.data);

        if (response.data && response.data.url) {
          return response.data.url;
        } else {
          throw new Error('Invalid API response format');
        }
      }
    } catch (error) {
      console.error('Error generating snapshot:', error);
      setSnackbarMessage('Failed to generate snapshot. Please try again.');
      setSnackbarOpen(true);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [itysId]);

  const handleSnapshotToggle = (event) => {
    setGenerateSnapshot(event.target.checked);
    if (!event.target.checked) {
      setSnapshotUrl(null);
    }
  };

  const shareFunction = async (platform) => {
    let currentSnapshotUrl = snapshotUrl;
    if (generateSnapshot && !currentSnapshotUrl) {
      currentSnapshotUrl = await generateAndSaveSnapshot();
      setSnapshotUrl(currentSnapshotUrl);
    }

    let sharedContent = `Check out this ITYS: "${itysContent.Title}"\n\n${shareableLink}`;
    if (currentSnapshotUrl) {
      //sharedContent += `\n\nSnapshot: ${currentSnapshotUrl}`;
      sharedContent = `${currentSnapshotUrl} \n\nLogin and check : ${shareableLink}`;
    }

    console.log('Shared content:', sharedContent);  // Debug log

    switch (platform) {
      case 'email':
        const emailSubject = encodeURIComponent('Check out this ITYS!');
        const emailBody = encodeURIComponent(sharedContent);
        window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;

        setSnackbarMessage(`Shared on ${platform}!`);
        setSnackbarOpen(true);
        break;
      case 'whatsapp':
        if (!isMobile) {
          const whatsappUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(sharedContent)}`;
          window.open(whatsappUrl, '_blank');

          setSnackbarMessage(`Content has been shared!`);
          setSnackbarOpen(true);
        }
        else {
          if (navigator.share) {
            try {
              /* Fetch the image using axios
              const response = await axios.get("https://172.20.10.3:3000/ITYSLogo.png", { responseType: 'blob' });
              // Convert the response to a blob
              //const blob = response.data;
              */
              const blob = ssBlob;
              const file = new File([blob], "picture.jpg", { type: 'image/jpeg' });
              // Put the file in an array for sharing
              const filesArray = [file];
              console.log("Here am I 3");
          
              // Check if navigator.canShare supports sharing files
              if (navigator.canShare && navigator.canShare({ files: filesArray }) && ssBlob !== null) {
                await navigator.share({
                  title: 'I Told You So',
                  text: `Check out this ITYS at ${shareableLink}`, // Ensure sharedContent is properly defined
                  files: filesArray,  // Attach the file to the share
                });
                console.log('Content shared successfully');
              } else {
                // Assume that files can not be shared (or snapshot not available) but content can still be shared.
                console.log("Sharing FILES is not supported on this device/browser.");
                await navigator.share({
                  title: 'Check out this ITYS!',
                  text: sharedContent, // Ensure sharedContent is properly defined
                  //url: `${shareableLink}`,  
                });
                console.log('Content shared successfully');
              }
            } catch (err) {
              const { message = '' } = err || {};
              console.error('Error sharing content:', err);
          
              // Handle specific error cases
              if (message.includes('user denied permission')) {
                console.log('User denied permission:', message);
              }
              setSnackbarMessage(`Content sharing failed. If it happens again, please contact support team!`);
              setSnackbarOpen(true);
            }
          } else {
            console.log('Share API not supported on this device.');
            // Optionally, you can provide a fallback for devices that don't support the Share API.
            setSnackbarMessage(`Content sharing API not supported on this device!`);
            setSnackbarOpen(true);
          }
        }
        /* This is working code. We decided to invoke the Share dialog so commenting this.
        if (isMobile) {
          const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(sharedContent)}`;
          window.open(whatsappUrl, '_blank');
        }
        else {
          const whatsappUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(sharedContent)}`;
          window.open(whatsappUrl, '_blank');
        }
        */
        break;
      default:
        console.error('Unknown sharing platform');
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Share ITYS
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={shareableLink}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton onClick={handleCopyLink}>
                  <ContentCopyIcon />
                </IconButton>
              ),
            }}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={generateSnapshot}
                onChange={handleSnapshotToggle}
                disabled={isLoading}
              />
            }
            label="Generate publicly available snapshot of ITYS"
          />
          {generateSnapshot && (
            <Box mt={2} id="snapshot-container">
              <Typography variant="subtitle1">Preview:</Typography>
              <ItysItem itys={itysContent} forceShowDetails={true} editable={false} />
            </Box>
          )}
          {snapshotUrl && (
            <Typography variant="body2" mt={2}>
              Snapshot URL: {snapshotUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            startIcon={<EmailIcon />} 
            onClick={() => shareFunction('email')}
            disabled={isLoading}
          >
            Email
          </Button>
          <Button 
            startIcon={<WhatsAppIcon />} 
            onClick={() => shareFunction('whatsapp')}
            disabled={isLoading}
          >
            WhatsApp
          </Button>
          {isLoading && <CircularProgress size={24} />}
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default ShareItysModal;