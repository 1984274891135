import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container, 
  Box, 
  CssBaseline,
  ThemeProvider,
  createTheme,
  Link,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Carousel from 'react-material-ui-carousel';
import './MainPage.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#9c27b0', // purple
    },
    secondary: {
      main: '#e91e63', // pink
    },
  },
  typography: {
    fontFamily: '"Noto Sans", Arial, sans-serif',
  },
});

const Title = () => (
  <AppBar position="static" color="transparent" elevation={0}>
    <Toolbar className="title-toolbar">
      <Box component="img" src="/ITYS-Banner-Main.jpg" alt="I Told You So" className="app-banner" />
      <Box>
        <Button color="inherit" component={RouterLink} to="/auth">Login</Button>
        <Button color="inherit" variant="outlined" component={RouterLink} to="/auth" state={{ isLogin: false }} sx={{ ml: 2 }}>
          Sign Up
        </Button>
      </Box>
    </Toolbar>
  </AppBar>
);

const Description = () => (
  <>
    <Typography variant="h5" component="h2" gutterBottom className="page-subheading">
      Log your predictions, track their accuracy, and share your insights with the world.
    </Typography>
    <Typography variant="body1" paragraph className="feature-list">
      • Record your everyday predictions and hunches<br/>
      • Get timestamped proof of your foresight<br/>
      • Share your validated predictions with friends and colleagues<br/>
      • Build your reputation as a forward-thinker<br/>
      • Make promises to motivate people around you and fulfill them<br/>
      • Challenge your friends and colleagues and sharpen your thought processes with feedback
    </Typography>
  </>
);

const ImmediateCTA = () => (
  <Button 
    variant="contained" 
    size="small" 
    endIcon={<ArrowForwardIcon />}
    component={RouterLink}
    to="/signup"
    className="get-started-button"
  >
    Start Predicting Now
  </Button>
);

const Samples = () => (
  <Box className="predictions-showcase">
    <Typography variant="h6" gutterBottom className="samples-heading">
      What people are doing on I Told You So
    </Typography>
    <Carousel
      animation="slide"
      indicators={true}
      navButtonsAlwaysVisible={true}
      navButtonsProps={{
        style: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderRadius: 0,
          color: 'black',
        }
      }}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
        <Box
          key={num}
          component="img"
          src={`/images/prediction_${num}.png`}
          alt={`Prediction ${num}`}
          className="prediction-image"
        />
      ))}
    </Carousel>
  </Box>
);

const Footer = () => (
  <Box component="footer" className="footer">
    <Container maxWidth="sm">
      <Typography variant="body1" align="center">
        © 2024 I Told You So. All rights reserved.
      </Typography>
      <Typography variant="body2" align="center" className="footer-buttons">
        <Link color="inherit" component={RouterLink} to="/terms">Terms of Service</Link>
        <Link color="inherit" component={RouterLink} to="/privacy" sx={{ ml: 2 }}>Privacy Policy</Link>
        <Link color="inherit" href="mailto:recordkeeper@itoldyouso.in" sx={{ ml: 2 }}>Contact Support</Link>
      </Typography>
    </Container>
  </Box>
);

const MainPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="app-container">
        <Title />
        <Container component="main" className="main-content">
          <Description />
          <ImmediateCTA />
          <Samples />
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default MainPage;