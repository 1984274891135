import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from '../services/api';
import { 
  TextField, Button, Typography, Container, CircularProgress, 
  Select, MenuItem, FormControl, InputLabel, Avatar, Box
} from '@mui/material';
import { updateUserStart, updateUserSuccess, updateUserFailure } from './authSlice';
import { fetchProfile, getAvatarContent } from '../utils/userUtils';
import './ProfileEdit.css';

const ProfileEdit = () => {
  const [formData, setFormData] = useState({
    PersonFirstName: '',
    PersonLastName: '',
    Handle: '',
    Phone: '',
    Gender: '',
    City: '',
  });
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { user, isLoading, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { userData, photoPreview } = await fetchProfile();
        setFormData(userData);
        setPhotoPreview(photoPreview);
      } catch (err) {
        if (err.message === 'Session expired. Please login again.') {
          dispatch(updateUserFailure(err.message));
          navigate('/login');
        } else {
          dispatch(updateUserFailure(err.message));
        }
      }
    };

    getProfile();
  }, [dispatch, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfilePhoto(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPhotoPreview(null);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.PersonFirstName) newErrors.PersonFirstName = 'First name is required';
    if (formData.Phone && !/^\d{10}$/.test(formData.Phone)) newErrors.Phone = 'Phone must be 10 digits';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    dispatch(updateUserStart());
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));
      if (profilePhoto) {
        formDataToSend.append('profilePhoto', profilePhoto);
      }
      const response = await axios.put('/persons/profile', formDataToSend, {
        headers: { 
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });
      dispatch(updateUserSuccess(response.data));
      navigate('/feed');
    } catch (err) {
      dispatch(updateUserFailure(err.response?.data?.message || 'An error occurred'));
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Container component="main" maxWidth="xs" className="profile-edit-container">
      <Typography component="h1" variant="h5">Edit Profile</Typography>
      <Box display="flex" justifyContent="center" width="100%" my={2}>
        <Avatar 
          className="profile-photo-preview"
          {...getAvatarContent(photoPreview, formData.PersonFirstName, formData.PersonLastName)}
        />
      </Box>
      <form onSubmit={handleSubmit} className="profile-edit-form">
        <input
          accept="image/*"
          id="profile-photo-input"
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="profile-photo-input">
          <Button variant="contained" color="primary" component="span">
            Upload Photo
          </Button>
        </label>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="PersonFirstName"
          label="First Name"
          name="PersonFirstName"
          value={formData.PersonFirstName}
          onChange={handleChange}
          error={!!errors.PersonFirstName}
          helperText={errors.PersonFirstName}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="PersonLastName"
          label="Last Name"
          name="PersonLastName"
          value={formData.PersonLastName}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="Handle"
          label="Handle"
          name="Handle"
          value={formData.Handle}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="Phone"
          label="Phone"
          name="Phone"
          value={formData.Phone}
          onChange={handleChange}
          error={!!errors.Phone}
          helperText={errors.Phone}
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select
            labelId="gender-label"
            id="Gender"
            name="Gender"
            value={formData.Gender}
            onChange={handleChange}
            label="Gender"
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Prefer Not To Say">Prefer Not To Say</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="City"
          label="City"
          name="City"
          value={formData.City}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="submit-button"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Update Profile'}
        </Button>
      </form>
    </Container>
  );
};

export default ProfileEdit;