import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from '../services/api';
import ItysItem from './ItysItem';
import { Container, Grid, Button, Typography, Box, Avatar, Card, CardContent, IconButton, Menu, MenuItem, Checkbox, FormControlLabel, useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fetchProfile, getAvatarContent } from '../utils/userUtils';
import { updateUserFailure } from './authSlice';

const ItysFeed = () => {
  const [itysList, setItysList] = useState([]);
  const [filteredItysList, setFilteredItysList] = useState([]);
  const [error, setError] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    Creator: false,
    Signatory: false,
    Challenger: false,
    Spectator: false
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchItysList();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [itysList, filters, userProfile]);

  const fetchItysList = async () => {
    try {
      const response = await axios.get('/itys/list', {
        headers: { 
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true
      });
      setItysList(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const { userData, photoPreview, displayName } = await fetchProfile();
      setUserProfile({ ...userData, photoPreview, displayName });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.message === 'Session expired. Please login again.') {
      dispatch(updateUserFailure(error.message));
      navigate('/login');
    } else {
      setError(error.message || 'An error occurred');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axios('/auth/logout', {
        method: 'POST',
        headers: {   Authorization: `Bearer ${localStorage.getItem('token')}`},
        withCredentials: true
      });
      console.log(response.data);
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      navigate('/auth');
    } catch (error) {
      console.log('Exception in handleLogout');
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      handleError(error);
    }
  };

  const handleAvatarClick = () => {
    navigate('/profile/edit');
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const applyFilters = () => {
    if (!userProfile) return;

    const filteredList = itysList.filter(itys => {
      if (Object.values(filters).every(v => !v)) return true; // If no filters are selected, show all items
      
      return (
        (filters.Creator && itys.Creator === userProfile._id) ||
        (filters.Signatory && itys.Signatory.includes(userProfile._id)) ||
        (filters.Challenger && itys.Challenger.includes(userProfile._id)) ||
        (filters.Spectator && itys.Spectator.includes(userProfile._id))
      );
    });

    setFilteredItysList(filteredList);
  };

  /*
        <Box className="banner-image-top">
        <img alt="I Told You So logo" src="/ITYS-Banner-Main.jpg" className="logo"/>
      </Box>
  */
  
  return (
    <Container maxWidth="sm" disableGutters> 
      <Box 
        sx={{
          width: '100%',
          height: 'auto',
          maxHeight: '200px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f0f0', // Add a background color in case the image is slow to load
        }}
      >
        <img 
          src="/ITYS-Banner-Main.jpg" 
          alt="I Told You So logo" 
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box px={0.5}>
        {error && <Typography color="error">{error}</Typography>}
        <Card sx={{ mb: 2, mt: 2 }}>
          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar 
                sx={{ mr: 2, cursor: 'pointer' }} 
                onClick={handleAvatarClick}
                {...getAvatarContent(userProfile?.photoPreview, userProfile?.PersonFirstName, userProfile?.PersonLastName)} 
              />
              <Typography variant="h6">
                {userProfile ? `${userProfile.displayName}` : 'Loading...'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/create"
                startIcon={<AddIcon />}
                sx={{ mr: 1 }}
              >
                {isMobile ? null : 'Create ITYS'}
              </Button>
              <IconButton color="primary" onClick={handleFilterClick} sx={{ mr: 1 }}>
                <FilterListIcon />
              </IconButton>
              <IconButton color="primary" onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
        >
          <MenuItem>
            <FormControlLabel
              control={<Checkbox checked={filters.Creator} onChange={handleFilterChange} name="Creator" />}
              label="Creator"
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              control={<Checkbox checked={filters.Signatory} onChange={handleFilterChange} name="Signatory" />}
              label="Signatory"
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              control={<Checkbox checked={filters.Challenger} onChange={handleFilterChange} name="Challenger" />}
              label="Challenger"
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              control={<Checkbox checked={filters.Spectator} onChange={handleFilterChange} name="Spectator" />}
              label="Spectator"
            />
          </MenuItem>
        </Menu>
        <Grid container spacing={1}>
          {filteredItysList.map(itys => (
            <Grid item xs={12} key={itys.ItysId}>
              <ItysItem itys={itys} currentProfile={userProfile} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ItysFeed;