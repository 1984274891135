import React, { useState, useEffect } from 'react';
import axios, { getBaseUrl } from '../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loginStart, loginSuccess, loginFailure } from './authSlice';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.isLogin === false) {
      setIsLogin(false);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const errormsg = urlParams.get('beerror');
    if (errormsg) {
      //setError(decodeURIComponent(errormsg));
      dispatch(loginFailure(errormsg)); 
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [location]);

  const [formData, setFormData] = useState({
    PersonFirstName: '',
    PersonLastName: '',
    Email: '',
    Password: ''
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); 
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.PersonFirstName) newErrors.PersonFirstName = 'First name is required';
    if (!formData.Email) newErrors.Email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.Email)) newErrors.Email = 'Email is invalid';
    if (!formData.Password) newErrors.Password = 'Password is required';
    else if (formData.Password.length < 6) newErrors.Password = 'Password must be at least 6 characters';
    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isLogin)
    {
        dispatch(loginStart());
        try {
            const endpoint = '/auth/local/login';
            const response = await axios.post('/auth/local/login', formData, { withCredentials: true });
            console.log(response.data);
            dispatch(loginSuccess({ user: response.data.user, token: response.data.token }));
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('isAuthenticated', 'true');
            navigate('/feed');
          } catch (error) {
            dispatch(loginFailure(error.response?.data?.message || 'An error occurred'));  
            console.error('Authentication error:', error.response.data);
          }
    }
    else //signup
    {
      console.log("In handleSubmit for signup");
      if (!validateForm()) return;
      try {
        const endpoint = '/auth/local/signup';
        const response = await axios.post('/auth/local/signup', formData, { withCredentials: true });
        console.log(response.data);
        dispatch(loginStart());
        dispatch(loginSuccess({ user: response.data.user, token: response.data.token }));
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/profile/edit');
      } catch (error) {
        dispatch(loginFailure(error.response?.data?.message || 'An error occurred'));  
        console.error('Signup error:', error.response.data);
      }
    }
  };

  const handleGoogleLogin = () => {
    const url = getBaseUrl() + "/auth/google";
    //url = `${process.env.REACT_APP_API_URL}/auth/google`;
    console.log(url);
    window.location.href = url;
    //window.location.href = `${api.getBaseUrl}/auth/google`;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box 
        sx={{
          width: '100%',
          height: 'auto',
          maxHeight: '150px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f0f0',
          mb: 1,
        }}
      >
        <img 
          src="/ITYS-Banner-Main.jpg" 
          alt="I Told You So logo" 
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          {isLogin ? 'Login' : 'Sign Up'}
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {!isLogin && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                name="PersonFirstName"
                autoFocus
                value={formData.PersonFirstName}
                label="First Name"
                onChange={handleChange}
                error={!!errors.PersonFirstName}
                helperText={errors.PersonFirstName}
              />
              <TextField
                margin="normal"
                fullWidth
                name="PersonLastName"
                label="Last Name"
                onChange={handleChange}
              />
            </>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            name="Email"
            label="Email Address"
            autoComplete="email"
            autoFocus
            value={formData.Email}
            onChange={handleChange}
            error={!!errors.Email}
            helperText={errors.Email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="Password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.Password}
            onChange={handleChange}
            error={!!errors.Password}
            helperText={errors.Password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="submit-button"
            disabled={isLoading}
            sx={{ mt: 3, mb: 2 }}
          >
            {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                isLogin ? 'Login' : 'Sign Up'
              )
            }
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleGoogleLogin}
            sx={{ mb: 2 }}
          >
            Login with Google
          </Button>
          <Button
            fullWidth
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? 'Need an account? Sign Up' : 'Already have an account? Login'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Auth;