import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container, 
  Box, 
  CssBaseline,
  ThemeProvider,
  createTheme
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './MainPage.css';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#9c27b0', // purple
    },
    secondary: {
      main: '#e91e63', // pink
    },
  },
});


const TermsOfServicePage = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" className="terms-privacy-container">
        <Button onClick={() => navigate('/')} className="back-button">Back to Main Page</Button>
        <Typography variant="h3" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body2" gutterBottom>
          Last updated: July 21, 2024
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using the I Told You So (ITYS) service, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access the service.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          2. Description of Service
        </Typography>
        <Typography variant="body1" paragraph>
          ITYS provides a platform for users to log predictions and track their accuracy. Users can create, share, and participate in predictions made by others.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          To use certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          4. User Content
        </Typography>
        <Typography variant="body1" paragraph>
          You are solely responsible for the content of your predictions and any other information you post or share on ITYS. You agree not to post content that is illegal, offensive, or violates the rights of others.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          5. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          The Service and its original content, features, and functionality are owned by ITYS and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          6. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          7. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall ITYS, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          8. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. What constitutes a material change will be determined at our sole discretion.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at recordkeeper@itoldyouso.in.
        </Typography>
      </Container>
    </ThemeProvider>
  );
};

const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" className="terms-privacy-container">
        <Button onClick={() => navigate('/')} className="back-button">Back to Main Page</Button>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body2" gutterBottom>
          Last updated: July 21, 2024
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information you provide directly to us when you create an account and use our service. This may include your name, email address, profile information, and any predictions or content you post on ITYS.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to provide, maintain, and improve our services, to communicate with you, to monitor and analyze trends and usage, and to carry out any other purpose for which the information was collected.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          3. Information Sharing and Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information as follows:
          <br />• With other users in accordance with the privacy settings you choose for your predictions
          <br />• With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf
          <br />• In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          4. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We store the information we collect about you for as long as is necessary for the purpose(s) for which we originally collected it. We may retain certain information as required by law or for legitimate business purposes.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          5. Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          6. Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You may update, correct, or delete your account information at any time by logging into your account. You may also request the deletion of your account by contacting us.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          7. Changes to this Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may change this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className="section-title">
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this privacy policy, please contact us at: recordkeeper@itoldyouso.in.
        </Typography>
      </Container>
    </ThemeProvider>
  );
};

export { TermsOfServicePage, PrivacyPolicyPage };