import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../services/api';
import ItysItem from './ItysItem';
import { Container, Typography, CircularProgress, Paper, Button, Box } from '@mui/material';

const ItysItemStandalone = () => {
  const { id } = useParams();
  const [itys, setItys] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchItys = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const response = await axios.get(`/itys/${id}`, { headers , withCredentials: true });
        setItys(response.data);
        setIsAuthenticated(true);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Authentication failed
          setIsAuthenticated(false);
        } else {
          setError(error.message || 'An error occurred');
        }
        setLoading(false);
      }
    };

    fetchItys();
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  if (!isAuthenticated) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Thank you for your interest!
          </Typography>
          <Typography variant="body1" paragraph>
            To view this ITYS content, please sign up or log in to your account.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button component={Link} to="/signup" variant="contained" color="primary" sx={{ mr: 2 }}>
              Sign Up
            </Button>
            <Button component={Link} to="/login" variant="outlined" color="primary">
              Log In
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      {itys ? (
        <div id="itys-content">
          <ItysItem itys={itys} />
        </div>
      ) : (
        <Typography>No ITYS found</Typography>
      )}
    </Container>
  );
};

export default ItysItemStandalone;