import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import axios from '../services/api';
import { fetchProfile, getAvatarContent } from '../utils/userUtils';

const PersonSearchInput = ({ label, value, onChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchTerm.length > 1) {
        try {
          const response = await axios.get(`/persons/search?q=${searchTerm}`, {
            headers: { 
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials: true
          });
          const suggestionsWithProfiles = await Promise.all(response.data.map(async (person) => {
            const { userData, photoPreview } = await fetchProfile(person._id);
            return { ...person, photoPreview };
          }));
          setSuggestions(suggestionsWithProfiles);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };

    const timer = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSuggestionClick = (person) => {
    onChange(person);
    setSearchTerm('');
    setSuggestions([]);
  };

  return (
    <div>
      <TextField
        label={label}
        value={searchTerm}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      {suggestions.length > 0 && (
        <List>
          {suggestions.map((person) => (
            <ListItem key={person.PersonId} button onClick={() => handleSuggestionClick(person)}>
              <ListItemAvatar>
                <Avatar {...getAvatarContent(person.photoPreview, person.PersonFirstName, person.PersonLastName)} />
              </ListItemAvatar>
              <ListItemText primary={person.PersonLastName ? `${person.PersonFirstName} ${person.PersonLastName}` : person.PersonFirstName} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default PersonSearchInput;