import api from '../services/api';
import { Buffer } from 'buffer-from';

// The Id in the parameter is ObjectId and not PersonId
export const fetchProfile = async (Id = null) => {
  try {
    const endpoint = Id ? `/persons/profile/id/${Id}` : '/persons/profile';
    const response = await api.get(endpoint, {
      headers: { 
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      withCredentials: true
    });
    //If there is no error, we mark this property here. This is needed for Google login where we have no place to set this.
    localStorage.setItem('isAuthenticated', 'true');

    let photoPreview = null;
    if (response.data.ProfilePhoto && response.data.ProfilePhoto.data) {
      //photoPreview = `data:${response.data.ProfilePhoto.contentType};base64,${Buffer.from(response.data.ProfilePhoto.data).toString('base64')}`;
      photoPreview = `data:${response.data.ProfilePhoto.contentType};base64,${response.data.ProfilePhoto.data}`;
      //photoPreview = `data:${response.data.ProfilePhoto.contentType};base64,${btoa(response.data.ProfilePhoto.data)}`;
      //console.log(photoPreview);
    }

    let displayName = null;
    let fname = response.data.PersonFirstName;
    let lname = response.data.PersonLastName;
    if (fname && lname) {
      //displayName = `${fname[0].toUpperCase()} ${lname[0].toUpperCase()}`;
      displayName = `${fname} ${lname}`;
    } else 
      displayName = `${fname}`;

    return {
      userData: response.data,
      photoPreview,
      displayName 
    };
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new Error('Session expired. Please login again.');
    } else {
      /*if (err.stack) {
        // Parse the stack trace
        const stackLines = err.stack.split('\n');
        if (stackLines.length > 1) {
          // The second line of the stack usually contains the file and line number
          const errorLine = stackLines[1].trim();
          console.error('Error location:', errorLine);
        }
      }*/
      console.log('Uncaught error:', err);
      throw new Error(err.response?.data?.message || 'An error occurred while fetching profile');
    }
  }
};

export const getAvatarContent = (photoPreview, firstName, lastName) => {
  if (photoPreview) {
    return { src: photoPreview, alt: "Profile Preview" };
  } else if (firstName && lastName) {
    return { children: `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` };
  } else if (firstName) {
    return { children: firstName[0].toUpperCase() };
  } else {
    return {};
  }
};