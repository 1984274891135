import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import ItysFeed from './components/ItysFeed';
import ItysForm from './components/ItysForm';
import ItysItemStandalone from './components/ItysItemStandalone';
//import Login from './components/Login';
//import Signup from './components/Signup';
import ProfileEdit from './components/ProfileEdit';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from './components/store';
import Auth from './components/Auth';
import MainPage from './components/MainPage';
import { TermsOfServicePage, PrivacyPolicyPage } from './components/SupportPages';
import { setupInterceptors } from './services/api';


const AppContent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setupInterceptors(navigate);
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/terms" element={<TermsOfServicePage />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/feed" element={<ItysFeed />} />
      <Route path="/create" element={<PrivateRoute><ItysForm /></PrivateRoute>} />
      <Route path="/edit/:id" element={<PrivateRoute><ItysForm /></PrivateRoute>} />
      <Route path="/itys/:id" element={<PrivateRoute><ItysItemStandalone /></PrivateRoute>} />
      <Route path="/profile/edit/" element={<ProfileEdit />} />
    </Routes>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <CssBaseline />
        <AppContent />
      </Router>
    </Provider>
  );
};

          //<Route path="/login" element={<Login />} />
          //<Route path="/signup" element={<Signup />} />
          //<Route path="/feed" element={<ItysFeed />} />
          //<Route path="/create" element={<ItysForm />} />

// PrivateRoute component to handle authentication
const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  return isAuthenticated ? children : <Navigate to="/auth" />;
};

export default App;
