import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from '../services/api';
import { 
  TextField, Button, Container, Typography, Box, Card, CardContent, Select, 
  MenuItem, FormControl, InputLabel, Chip, Avatar, IconButton, Snackbar
} from '@mui/material';
import PersonSearchInput from './PersonSearchInput';
import { fetchProfile, getAvatarContent } from '../utils/userUtils';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BalanceIcon from '@mui/icons-material/Balance';
import './ItysForm.css';

const ItysForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const editMode = location.state?.editMode || 'full';

  const [itys, setItys] = useState({
    Title: '',
    Content: '',
    Creator: null,
    Visibility: 'Public',
    Signatory: [],
    Spectator: [],
    Challenger: [],
    TimeOfReckoning: '',
    FinalSettlementStatus: 'Pending',
    CreationDate: null
  });
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(true);
  const [editingField, setEditingField] = useState(null);
  const [addingParticipant, setAddingParticipant] = useState({
    Signatory: false,
    Spectator: false,
    Challenger: false
  });
  const [isEditable, setIsEditable] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (id) {
      fetchItys(id);
    } else {
      // Set default TimeOfReckoning to midnight of next day
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 2);
      tomorrow.setHours(0, 0, 0, 0);
      setItys(prev => ({
        ...prev,
        TimeOfReckoning: tomorrow.toISOString().slice(0, 16),
        CreationDate: new Date().toISOString()
      }));
    }
  }, [id]);

  useEffect(() => {
    if (itys.CreationDate) {
      const creationDate = new Date(itys.CreationDate);
      const now = new Date();
      const timeDifference = now - creationDate;
      const minutesPassed = timeDifference / (1000 * 60);
      setIsEditable(minutesPassed <= 30);
    }
  }, [itys.CreationDate]);

  const checkTOREditability = () => {
    const now = new Date();
    const tor = new Date(itys.TimeOfReckoning);
    const creationDate = new Date(itys.CreationDate);

    // Check if TimeOfReckoning is in the past
    if (tor < now) {
      setSnackbarMessage("You can not change the Time of Reckoning that has already passed.");
      setSnackbarOpen(true);
      return false;
    }

    // Check if TimeOfReckoning is within 24 hours and CreationDate is more than 30 minutes ago
    const hoursDifference = (tor - now) / (1000 * 60 * 60);
    const minutesSinceCreation = (now - creationDate) / (1000 * 60);

    if (hoursDifference <= 24 && minutesSinceCreation > 30) {
      setSnackbarMessage("You can not change Time Of Reckoning within the last 24 hours of its arrival.");
      setSnackbarOpen(true);
      return false;
    }

    return true;
  };

  const checkFinalSettlementStatusEditability = () => {
    const now = new Date();
    const tor = new Date(itys.TimeOfReckoning);
    const timeDifference = now - tor;
    const hoursPassed = timeDifference / (1000 * 60 * 60);

    if (hoursPassed > 24) {
      setSnackbarMessage("Final Settlement Status can be changed only till 24 hours after the Time of Reckoning.");
      setSnackbarOpen(true);
      return false;
    }

    return true;
  };

  const fetchItys = async (id) => {
    try {
      const response = await axios.get(`/itys/${id}`, {
        headers: { 
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true 
      });
      const fetchedItys = response.data;

      const creatorDetails = await fetchProfile(fetchedItys.Creator);

      const fetchDetailedPersons = async (personIds) => {
        return await Promise.all(personIds.map(id => fetchProfile(id)));
      };

      const [signatoryDetails, spectatorDetails, challengerDetails] = await Promise.all([
        fetchDetailedPersons(fetchedItys.Signatory),
        fetchDetailedPersons(fetchedItys.Spectator),
        fetchDetailedPersons(fetchedItys.Challenger)
      ]);

      setItys({
        ...fetchedItys,
        Creator: creatorDetails,
        Signatory: signatoryDetails,
        Spectator: spectatorDetails,
        Challenger: challengerDetails
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!isEditable && (name === 'Title' || name === 'Content')) {
      setSnackbarMessage("Sorry. Title and Content cannot be edited once the ITYS is more than 30 minutes old.");
      setSnackbarOpen(true);
      return;
    }
    if (name === 'TimeOfReckoning') {
      const newDate = new Date(value);
      setItys({ ...itys, [name]: newDate.toISOString() });
    } else {
      setItys({ ...itys, [name]: value });
    }
  };

  const handleEditClick = (field) => {
    if (field === 'TimeOfReckoning') {
      if (checkTOREditability()) {
        setEditingField(field);
      }
    } else if (field === 'FinalSettlementStatus') {
      if (checkFinalSettlementStatusEditability()) {
        setEditingField(field);
      }
    } else {
      setEditingField(field);
    }
  };

  const handlePersonAdd = (category) => async (person) => {
    const personDetails = await fetchProfile(person._id);
    setItys(prev => ({
      ...prev,
      [category]: [...prev[category], personDetails]
    }));
  };

  const handlePersonRemove = (category, personId) => {
    setItys(prev => ({
      ...prev,
      [category]: prev[category].filter(p => p.userData.PersonId !== personId)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        Visibility: itys.Visibility,
        TimeOfReckoning: itys.TimeOfReckoning,
        FinalSettlementStatus: itys.FinalSettlementStatus,
        Signatory: itys.Signatory.map(p => p.userData._id),
        Spectator: itys.Spectator.map(p => p.userData._id),
        Challenger: itys.Challenger.map(p => p.userData._id)
      };

      // Only include Title and Content if they are editable
      if (isEditable) {
        dataToSend.Title = itys.Title;
        dataToSend.Content = itys.Content;
      }

      const headers = { 
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };

      if (id) {
        await axios.put(`/itys/${id}`, dataToSend, { headers, withCredentials: true });
      } else {
        await axios.post('/itys/create', dataToSend, { headers, withCredentials: true });
      }

      navigate('/feed');
    } catch (error) {
      setError(error.message);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'I was Right':
        return { className: 'status-right', emoji: '😊' };
      case 'I was Wrong':
        return { className: 'status-wrong', emoji: '😔' };
      default:
        return { className: 'status-pending', emoji: '🤔' };
    }
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(',', '');
  };

  const toggleAddParticipant = (category) => {
    setAddingParticipant(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const renderParticipantSection = (category) => (
    <div key={category} className="participant-section">
      <div className="group-header">
        <h3>{category}</h3>
        <IconButton className="add-member-button" onClick={() => toggleAddParticipant(category)}>
          <PersonAddIcon />
        </IconButton>
      </div>
      <div className="avatar-list">
        {itys[category].map((person) => (
          <Chip
            key={person.userData.PersonId}
            avatar={<Avatar {...getAvatarContent(person.photoPreview, person.userData.PersonFirstName, person.userData.PersonLastName)} />}
            label={person.displayName}
            onDelete={() => handlePersonRemove(category, person.userData.PersonId)}
            style={{ margin: '2px' }}
          />
        ))}
      </div>
      {addingParticipant[category] && (
        <PersonSearchInput
          label={`Add ${category}`}
          onChange={handlePersonAdd(category)}
        />
      )}
    </div>
  );

  const statusStyle = getStatusStyle(itys.FinalSettlementStatus);

  return (
    <Container maxWidth="sm">
      <Card className="itys-card">
        <CardContent>
          {error && <Typography color="error">{error}</Typography>}
          <form onSubmit={handleSubmit}>
            <div className="itys-header-top">
              <div className="itys-visibility">
                {editingField === 'Visibility' ? (
                  <FormControl fullWidth margin="normal">
                    <Select
                      name="Visibility"
                      value={itys.Visibility}
                      onChange={handleChange}
                      onBlur={() => setEditingField(null)}
                    >
                      <MenuItem value="Public">Public</MenuItem>
                      <MenuItem value="Private">Private</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    <span>{itys.Visibility}</span>
                    <IconButton size="small" onClick={() => handleEditClick('Visibility')}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
              </div>
              <div className={`itys-status ${statusStyle.className}`}>
                {editingField === 'FinalSettlementStatus' ? (
                  <FormControl fullWidth margin="normal">
                    <Select
                      name="FinalSettlementStatus"
                      value={itys.FinalSettlementStatus}
                      onChange={handleChange}
                      onBlur={() => setEditingField(null)}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="I was Right">I was Right</MenuItem>
                      <MenuItem value="I was Wrong">I was Wrong</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    <span>{statusStyle.emoji} {itys.FinalSettlementStatus}</span>
                    <IconButton size="small" onClick={() => handleEditClick('FinalSettlementStatus')}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            {itys.Creator && (
              <div className="itys-header-bottom">
                <div className="itys-creator">
                  <Avatar className="creator-avatar" {...getAvatarContent(itys.Creator.photoPreview, itys.Creator.userData.PersonFirstName, itys.Creator.userData.PersonLastName)} />
                  <span className="creator-name">{itys.Creator.displayName}</span>
                </div>
              </div>
            )}
            <div className="itys-main-content">
              <input
                className="itys-title-input"
                name="Title"
                value={itys.Title}
                onChange={handleChange}
                required
                placeholder="Title"
              />
              <div className="content-canvas">
                <textarea
                  name="Content"
                  value={itys.Content}
                  onChange={handleChange}
                  required
                  placeholder="What's your prediction?"
                />
              </div>
            </div>
            <div className="itys-footer">
              {['Signatory', 'Spectator', 'Challenger'].map(renderParticipantSection)}
            </div>
            <div className="itys-time-of-reckoning">
              {editingField === 'TimeOfReckoning' ? (
                <TextField
                  name="TimeOfReckoning"
                  type="datetime-local"
                  value={formatDateForInput(itys.TimeOfReckoning)}
                  onChange={handleChange}
                  onBlur={() => setEditingField(null)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Time of Reckoning"
                />
              ) : (
                <div className="itys-date">
                  <BalanceIcon /> 
                  <span>{formatDate(itys.TimeOfReckoning)}</span>
                  <IconButton size="small" onClick={() => handleEditClick('TimeOfReckoning')}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
            </div>
            <div className="form-actions">
              <Button onClick={() => navigate('/feed')} color="secondary" variant="contained">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Save
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ItysForm;